@import '../../../../scss/theme-bootstrap';

.header-gnav-section {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: $cr19-text;
  @media #{$cr19-large-up} {
    flex-direction: row;
  }
  &__link-wrapper,
  &__label-wrapper {
    margin-bottom: 13px;
    .header-gnav-section__link,
    .header-gnav-section__label {
      @include text-title--small;
    }
  }
  &__label-wrapper {
    position: relative;
  }
  &__label {
    .gnav-header-formatter__sections-panel {
      span:not(.first-name) {
        font-family: $helvetica-bold-font-family;
        font-size: 20px;
        line-height: get-line-height(20px, 24px);
        margin-bottom: 13px;
        @media #{$cr19-large-up} {
          font-size: 28px;
          line-height: get-line-height(28px, 32px);
        }
      }
    }
  }
  &__subpanel-title {
    margin-bottom: 25px;
  }
  &__panel-link--header {
    @include text-link--style-2;
    @include gnav-text-link;
    font-family: $helvetica-bold-font-family;
    color: $cr19-text;
    position: relative;
  }
  &__panel {
    &:after {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
      bottom: 0;
      content: '';
      display: block;
      height: 90px;
      position: fixed;
      width: 100%;
      z-index: 9999;
      pointer-events: none;
    }
  }
}
